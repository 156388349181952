var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "page-title" }, [_vm._v("Aging Report")]),
    _c(
      "div",
      { staticClass: "page-body" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "Orders", name: "first" } },
              [
                _c(
                  "el-row",
                  {
                    staticClass: "handler-bar",
                    attrs: {
                      gutter: 30,
                      type: "flex",
                      justify: "space-between",
                      align: "middle",
                    },
                  },
                  [
                    _c(
                      "el-col",
                      { staticStyle: { display: "flex" }, attrs: { span: 12 } },
                      [
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.selectCompany,
                                callback: function ($$v) {
                                  _vm.selectCompany = $$v
                                },
                                expression: "selectCompany",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        [
                          _c(
                            "div",
                            {
                              staticClass: "block",
                              staticStyle: { "margin-left": "20px" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                },
                                model: {
                                  value: _vm.value1,
                                  callback: function ($$v) {
                                    _vm.value1 = $$v
                                  },
                                  expression: "value1",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "titie-text", attrs: { span: 12 } },
                          [
                            _c("div", [
                              _c("div", [_vm._v("Credit Balance:")]),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v("$5,000,000.00")]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "titie-text", attrs: { span: 12 } },
                          [
                            _c("div", [
                              _c("div", [_vm._v("Amount Total:")]),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v("$400,000.00")]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-row", { staticClass: "handler-bar2" }, [
                  _c(
                    "div",
                    { staticClass: "table-block" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.tableLoading,
                              expression: "tableLoading",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.tableDataList },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              fixed: "",
                              prop: "OrderCreatedDate",
                              label: "Order Created Date",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "OrderStatus",
                              label: "Order Status",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "OrderNo",
                              label: "Order No.",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "VesselName",
                              label: "Vessel Name",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Quantity",
                              label: "Quantity",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Product",
                              label: "Product Grade/Specs/Sulphur",
                              width: "250",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Amount",
                              label: "Amount",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: _vm.$t(""),
                              fixed: "right",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "left",
                                          trigger: "click",
                                          "popper-class": "popover-btns",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "button-popover" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "button-item" },
                                              [
                                                _c("em", {
                                                  staticClass:
                                                    "el-icon-warning-outline",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("View"))
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "button-item" },
                                              [
                                                _c("em", {
                                                  staticClass:
                                                    "el-icon-tickets",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("Print"))
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("el-button", {
                                          staticClass: "more-button",
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                            size: "mini",
                                            icon: "el-icon-more",
                                          },
                                          slot: "reference",
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "Invoice", name: "second" } },
              [
                _c(
                  "el-row",
                  {
                    staticClass: "handler-bar",
                    attrs: {
                      gutter: 30,
                      type: "flex",
                      justify: "space-between",
                      align: "middle",
                    },
                  },
                  [
                    _c(
                      "el-col",
                      { staticStyle: { display: "flex" }, attrs: { span: 12 } },
                      [
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.selectCompany,
                                callback: function ($$v) {
                                  _vm.selectCompany = $$v
                                },
                                expression: "selectCompany",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        [
                          _c(
                            "div",
                            {
                              staticClass: "block",
                              staticStyle: { "margin-left": "20px" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                },
                                model: {
                                  value: _vm.value1,
                                  callback: function ($$v) {
                                    _vm.value1 = $$v
                                  },
                                  expression: "value1",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c("el-row", { staticClass: "handler-bar2" }, [
                  _c(
                    "div",
                    { staticClass: "table-block" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.tableLoading,
                              expression: "tableLoading",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.tableDataList },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              fixed: "",
                              prop: "OrderCreatedDate",
                              label: "Order Created Date",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "OrderStatus",
                              label: "Order Status",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "OrderNo",
                              label: "Order No.",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "VesselName",
                              label: "Vessel Name",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Quantity",
                              label: "Quantity",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Product",
                              label: "Product Grade/Specs/Sulphur",
                              width: "250",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Amount",
                              label: "Amount",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: _vm.$t(""),
                              fixed: "right",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "left",
                                          trigger: "click",
                                          "popper-class": "popover-btns",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "button-popover" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "button-item" },
                                              [
                                                _c("em", {
                                                  staticClass:
                                                    "el-icon-warning-outline",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("View"))
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "button-item" },
                                              [
                                                _c("em", {
                                                  staticClass:
                                                    "el-icon-tickets",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("Print"))
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("el-button", {
                                          staticClass: "more-button",
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                            size: "mini",
                                            icon: "el-icon-more",
                                          },
                                          slot: "reference",
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "Overdue", name: "third" } },
              [
                _c(
                  "el-row",
                  {
                    staticClass: "handler-bar",
                    attrs: {
                      gutter: 30,
                      type: "flex",
                      justify: "space-between",
                      align: "middle",
                    },
                  },
                  [
                    _c(
                      "el-col",
                      { staticStyle: { display: "flex" }, attrs: { span: 12 } },
                      [
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.selectCompany,
                                callback: function ($$v) {
                                  _vm.selectCompany = $$v
                                },
                                expression: "selectCompany",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        [
                          _c(
                            "div",
                            {
                              staticClass: "block",
                              staticStyle: { "margin-left": "20px" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                },
                                model: {
                                  value: _vm.value1,
                                  callback: function ($$v) {
                                    _vm.value1 = $$v
                                  },
                                  expression: "value1",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c("el-row", { staticClass: "handler-bar2" }, [
                  _c(
                    "div",
                    { staticClass: "table-block" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.tableLoading,
                              expression: "tableLoading",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.tableDataList },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              fixed: "",
                              prop: "OrderCreatedDate",
                              label: "Order Created Date",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "OrderStatus",
                              label: "Order Status",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "OrderNo",
                              label: "Order No.",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "VesselName",
                              label: "Vessel Name",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Quantity",
                              label: "Quantity",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Product",
                              label: "Product Grade/Specs/Sulphur",
                              width: "250",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Amount",
                              label: "Amount",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: _vm.$t(""),
                              fixed: "right",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "left",
                                          trigger: "click",
                                          "popper-class": "popover-btns",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "button-popover" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "button-item" },
                                              [
                                                _c("em", {
                                                  staticClass:
                                                    "el-icon-warning-outline",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("View"))
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "button-item" },
                                              [
                                                _c("em", {
                                                  staticClass:
                                                    "el-icon-tickets",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("Print"))
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("el-button", {
                                          staticClass: "more-button",
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                            size: "mini",
                                            icon: "el-icon-more",
                                          },
                                          slot: "reference",
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }