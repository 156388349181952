<template>
  <div>
    <div class="page-title">Aging Report</div>
    <div class="page-body">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Orders" name="first">
          <el-row class="handler-bar" :gutter="30" type="flex" justify="space-between" align="middle">
            <el-col :span="12" style="display: flex">
              <template>
                <el-select v-model="selectCompany" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
              <template>
                <div class="block" style="margin-left: 20px">
                  <el-date-picker
                    v-model="value1"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </div>
              </template>
            </el-col>
            <el-col :span="12">
              <el-col :span="12" class="titie-text">
                <div>
                  <div>Credit Balance:</div>
                  <div style="margin-left: 20px">$5,000,000.00</div>
                </div>
              </el-col>
              <el-col :span="12" class="titie-text">
                <div>
                  <div>Amount Total:</div>
                  <div style="margin-left: 20px">$400,000.00</div>
                </div>
              </el-col>
            </el-col>
          </el-row>
          <el-row class="handler-bar2">
            <div class="table-block">
              <el-table
                v-loading="tableLoading"
                :data="tableDataList"
                style="width: 100%">
                <el-table-column
                  fixed
                  prop="OrderCreatedDate"
                  label="Order Created Date"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="OrderStatus"
                  label="Order Status"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="OrderNo"
                  label="Order No."
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="VesselName"
                  label="Vessel Name"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="Quantity"
                  label="Quantity"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="Product"
                  label="Product Grade/Specs/Sulphur"
                  width="250">
                </el-table-column>
                <el-table-column
                  prop="Amount"
                  label="Amount"
                  width="200">
                </el-table-column>
                <el-table-column align="center" :label="$t('')" fixed="right">
                  <template slot-scope="scope">
                    <el-popover
                      placement="left"
                      trigger="click"
                      popper-class="popover-btns">
                      <div class="button-popover">
                        <div class="button-item">
                          <em class="el-icon-warning-outline"></em>
                          <span>{{ $t('View') }}</span>
                        </div>
                        <div class="button-item">
                          <em class="el-icon-tickets"></em>
                          <span>{{ $t('Print') }}</span>
                        </div>
                      </div>
                      <el-button class="more-button" slot="reference" type="text" size="mini" icon="el-icon-more"></el-button>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Invoice" name="second">
          <el-row class="handler-bar" :gutter="30" type="flex" justify="space-between" align="middle">
            <el-col :span="12" style="display: flex">
              <template>
                <el-select v-model="selectCompany" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
              <template>
                <div class="block" style="margin-left: 20px">
                  <el-date-picker
                    v-model="value1"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </div>
              </template>
            </el-col>
          </el-row>
          <el-row class="handler-bar2">
            <div class="table-block">
              <el-table
                v-loading="tableLoading"
                :data="tableDataList"
                style="width: 100%">
                <el-table-column
                  fixed
                  prop="OrderCreatedDate"
                  label="Order Created Date"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="OrderStatus"
                  label="Order Status"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="OrderNo"
                  label="Order No."
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="VesselName"
                  label="Vessel Name"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="Quantity"
                  label="Quantity"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="Product"
                  label="Product Grade/Specs/Sulphur"
                  width="250">
                </el-table-column>
                <el-table-column
                  prop="Amount"
                  label="Amount"
                  width="200">
                </el-table-column>
                <el-table-column align="center" :label="$t('')" fixed="right">
                  <template slot-scope="scope">
                    <el-popover
                      placement="left"
                      trigger="click"
                      popper-class="popover-btns">
                      <div class="button-popover">
                        <div class="button-item">
                          <em class="el-icon-warning-outline"></em>
                          <span>{{ $t('View') }}</span>
                        </div>
                        <div class="button-item">
                          <em class="el-icon-tickets"></em>
                          <span>{{ $t('Print') }}</span>
                        </div>
                      </div>
                      <el-button class="more-button" slot="reference" type="text" size="mini" icon="el-icon-more"></el-button>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Overdue" name="third">
          <el-row class="handler-bar" :gutter="30" type="flex" justify="space-between" align="middle">
            <el-col :span="12" style="display: flex">
              <template>
                <el-select v-model="selectCompany" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
              <template>
                <div class="block" style="margin-left: 20px">
                  <el-date-picker
                    v-model="value1"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </div>
              </template>
            </el-col>
          </el-row>
          <el-row class="handler-bar2">
            <div class="table-block">
              <el-table
                v-loading="tableLoading"
                :data="tableDataList"
                style="width: 100%">
                <el-table-column
                  fixed
                  prop="OrderCreatedDate"
                  label="Order Created Date"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="OrderStatus"
                  label="Order Status"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="OrderNo"
                  label="Order No."
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="VesselName"
                  label="Vessel Name"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="Quantity"
                  label="Quantity"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="Product"
                  label="Product Grade/Specs/Sulphur"
                  width="250">
                </el-table-column>
                <el-table-column
                  prop="Amount"
                  label="Amount"
                  width="200">
                </el-table-column>
                <el-table-column align="center" :label="$t('')" fixed="right">
                  <template slot-scope="scope">
                    <el-popover
                      placement="left"
                      trigger="click"
                      popper-class="popover-btns">
                      <div class="button-popover">
                        <div class="button-item">
                          <em class="el-icon-warning-outline"></em>
                          <span>{{ $t('View') }}</span>
                        </div>
                        <div class="button-item">
                          <em class="el-icon-tickets"></em>
                          <span>{{ $t('Print') }}</span>
                        </div>
                      </div>
                      <el-button class="more-button" slot="reference" type="text" size="mini" icon="el-icon-more"></el-button>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'agingReport',
  data () {
    return {
      // 时间选择器
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      // 默认tab-pane
      activeName: 'first',
      // selectCompany 选项
      selectCompany: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      // 表格数据
      tableDataList: [{
        OrderCreatedDate: 'Information',
        OrderStatus: 'Information',
        OrderNo: 'Information',
        VesselName: 'Information',
        Quantity: 'Information',
        Product: 'Information',
        Amount: 'Information'
      }],
    }
  },
  methods: {
    // 切换tab-pane
    handleClick(tab, event) {
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/ .el-tabs__nav-wrap::after {
    background-color: transparent;
  }
  .page-title{
    ont-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #1B1B1B;
  }
  .page-body{
    margin-top: 40px;
  }
  .titie-text{
    display: flex;
    align-items: center;
    justify-content: center;
    &>div{
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      color: #1B1B1B;
    }
  }
  .table-block {
    width: 100%;
    height: calc(100% - 166px);
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 5px #eaeaea;
  }
  .button-popover {
    @include flex-center-row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 12px;
    width: 100%;

    .button-item {
      // flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      color: #4a4d54e6;
      padding: 0 12px;

      i {
        font-size: 21px;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        padding: 5px 0 0px 0;
      }

      &:hover {
        color: $primary-color;
      }
    }
  }
  .handler-bar2{
    margin-top: 10px;
  }
</style>
